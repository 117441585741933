import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { apiSDKService } from "../../../../api/renewal/api.service";
import PretendardText from "../../../../components/atoms/text/pretendardText";
import MIDCard from "../../../../components/organisms/investment/midCard";
import MIDCardM from "../../../../components/organisms/investment/midCardM";
import Pagination from "../../../../components/organisms/pagination";
import MainSection from "../../../../components/templates/mainSection";
import MiddleNavBar from "../../../../components/templates/middleNavBar";
import useDisplay from "../../../../hooks/useDisplay";
import { financialInfoLang } from "../../../../lang/investment/financialInfoLang";
import { mainBannerLang } from "../../../../lang/lang";
import { route } from "../../../../router/route";
import { langState } from "../../../../store/langState";
import { fileDownload } from "../../../../util/file-management";


const IrInfoPage = () => {
  const { isMobile, isTablet } = useDisplay();
  const isSmall = isMobile || isTablet;

  const location = useLocation();
  const { pathname } = useLocation();

  const { page } = useParams();
  const langValue = useRecoilValue(langState);

  const [totalCtn, setTotalCtn] = useState(0);

  const [ruleData, setRuleData] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [pagination, setPagination] = useState({
    size: 10, // 한 페이지에 표시될 게시글 수
    rangeSize: 5, // 하단 페이지 사이즈
    totalCount: 0,
    totalPages: 0,
    first: false,
    last: false,
  });

  useEffect(() => {
    getMMInfoListAPI();
  }, [location, langValue]);

  const getMMInfoListAPI = async () => {
    try {
      const irMaterials = await apiSDKService.getIRMaterials(page, pagination.size);

      setTotalCtn(irMaterials.total);
      setPagination({
        ...pagination,
        totalCount: irMaterials.total,
        totalPages: Math.ceil(irMaterials.total / pagination.size),
        first: irMaterials.page === 1,
        last: irMaterials.hasMore,
      });
      setDataList(irMaterials.items);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <MainSection
        bg={
          isMobile
            ? "/assets/imgs/main-section/m_management_info.png"
            : "/assets/imgs/main-section/management_info.png"
        }
        title={mainBannerLang.irInfo.title[langValue]}
        content={mainBannerLang.irInfo.content[langValue]}
      />
      <MiddleNavBar pathname={pathname} />

      <div
        style={{
          padding: isMobile
            ? "90px 20px 80px"
            : isTablet
            ? "100px 70px"
            : "120px 0px 148px 0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1400,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "18.9px",
                  marginTop: 40,
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PretendardText
                style={{
                  color: "#778088",
                  fontSize: isTablet ? "14px" : "16px",
                  fontWeight: 400,
                  lineHeight: isTablet ? "18.9px" : "23.2px",
                }}
              >
                {langValue === "KR" ? "총" : "Total"} {totalCtn}{" "}
                {langValue === "KR" ? "건" : ""}
              </PretendardText>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              ></div>
            </div>
          )}

          <>
            <div
              style={{
                borderTop: "2px solid #778088",
                borderBottom: "2px solid #778088",
                marginTop: isMobile ? 10 : 20,
              }}
            >
              {dataList.map((item, index) => {
               
                return isSmall ? (
                  <MIDCardM
                    key={index}
                    data={item}
                    link={`${route.ir_info_detail}/${item._id}`}
                    number={index + 1 + parseInt(page - 1) * 10}
                    title={item.title}
                    date={moment.utc(item.date).format("YYYY.MM.DD")}
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                  />
                ) : (
                  <MIDCard
                    key={index}
                    data={item}
                    link={`${route.ir_info_detail}/${item._id}`}
                    number={index + 1 + parseInt(page - 1) * 10}
                    title={item.title}
                    date={moment.utc(item.date).format("YYYY.MM.DD")}
                    style={{
                      borderBottom:
                        dataList.length === index + 1
                          ? "none"
                          : "1px solid #ADB4BA",
                    }}
                  />
                );
              })}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                marginTop: isSmall ? 40 : 60,
              }}
            >
              <Pagination
                route={route.managementInfo}
                queryString={``}
                currentPage={page}
                totalPages={pagination.totalPages}
                rangeSize={pagination.rangeSize}
                first={pagination.first}
                last={pagination.last}
              />
            </div>
          </>

          {ruleData && ruleData.fileUrl ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: isSmall ? 50 : 0,
              }}
            >
              <div
                onClick={() => {
                  fileDownload(
                    ruleData.serverFileName,
                    ruleData.originFileName
                  );
                }}
                style={{
                  background: "#00AEEF",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 229,
                  height: 54,
                  position: isSmall ? "relative" : "absolute",
                  right: isSmall ? "unset" : 0,
                  bottom: isSmall ? "unset" : -26,
                  // cursor: "pointer",
                }}
              >
                <PretendardText
                  style={{
                    color: "#FFFFFF",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "26.1px",
                    marginRight: 5,
                  }}
                >
                  {financialInfoLang.regulation[langValue]}
                </PretendardText>
                <img
                  style={{}}
                  src="/assets/icons/download.svg"
                  alt="download"
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default IrInfoPage;
