const PDFViewer = ({ url }) => {
  const pdfUrl = `${process.env.PUBLIC_URL}${url}`;
  console.log("PDF URL:", pdfUrl);
  return (
    <div style={{ width: "100%", height: "1200px" }}>
      <object data={url} type="application/pdf" width="100%" height="100%">
        <p>이 브라우저는 PDF를 직접 표시하지 못합니다.</p>
      </object>
    </div>
  );
};

export default PDFViewer;
