export const data = {
  keyword: "",
  totalCtn: 1,
  startDate: "2023-01-01",
  endDate: "2023-12-31",
  // ruleData: {
  //   fileUrl: "https://example.com/rules.pdf",
  //   serverFileName: "company_rules_2023.pdf",
  //   originFileName: "회사 규정 2023.pdf",
  // },
  dataList: [
    {
      id: 1,
      title: "테스트 - ir 지금은 신주발행공고로 보임",
      createdAt: "2024-09-27T09:00:00Z",
      fileUrl: "/assets/pdfs/신주발행공고.pdf",
      serverFileName: "신주발행공고.pdf",
      originFileName: "신주발행공고.pdf",
    },
  ],
  pagination: {
    size: 1,
    rangeSize: 1,
    totalCount: 1,
    totalPages: 1,
    first: true,
    last: false,
  },
};
