export const data = [
  {
    id: "67b5770436b3a716912b690e",
    img: "/assets/imgs/job-announce/경영지원본부_재무회계세무.jpg",
  },
  {
    id: "67b5770436b3a716912b690e",
    img: "/assets/imgs/job-announce/경영지원본부_재무회계세무.jpg",
  },
  {
    id: "67b5283836b3a71691270bae",
    img: "/assets/imgs/job-announce/기반기술사업부_QA담당자.jpg",
  },
  {
    id: "67b306a236b3a7169121303d",
    img: "/assets/imgs/job-announce/PA팀_PA담당자.jpg",
  },
  {
    id: "677b76d6512a11ee46278436",
    img: "/assets/imgs/job-announce/PA팀_QA시험.jpg",
  },
  {
    id: "675f6db47b4be71c383763c9",
    img: "/assets/imgs/job-announce/경영지원본부_해외마케팅.jpg",
  },
  {
    id: "67590bcd8eaf79605b593fbe",
    img: "/assets/imgs/job-announce/PA팀_양산품질.jpg",
  },
  {
    id: "674e511b8eaf79605b4daa5a",
    img: "/assets/imgs/job-announce/기술2부_SW 개발.jpg",
  },
  {
    id: "672dc98a42e16f1e2af4e8f2",
    img: "/assets/imgs/job-announce/기술7부_웹기획.jpg",
  },
  {
    id: "66f0e87a54528140cb4d8442",
    img: "/assets/imgs/job-announce/기술1부_전력전자 하드웨어 개발.jpg",
  },
  {
    id: "66f0e86354528140cb4d843a",
    img: "/assets/imgs/job-announce/기술1부_아트웍 개발.jpg",
  },
  {
    id: "66f0d59d54528140cb4d7404",
    img: "/assets/imgs/job-announce/기술2부_WEB 개발.jpg",
  },
  {
    id: "66f0d5bc54528140cb4d7408",
    img: "/assets/imgs/job-announce/기술2부_백엔드 프론트엔드개발.jpg",
  },
  {
    id: "66f0d55b54528140cb4d73f2",
    img: "/assets/imgs/job-announce/PA팀_환경시험.jpg",
  },
  {
    id: "66f0d56b54528140cb4d73f9",
    img: "/assets/imgs/job-announce/PA팀_솔레노이드.jpg",
  },
  {
    id: "66f0d68d54528140cb4d7425",
    img: "/assets/imgs/job-announce/기술7부_프로젝트관리자.jpg",
  },
  {
    id: "66b2b51a1d5b63eae88514e2",
    img: "/assets/imgs/job-announce/PA팀_생산관리 및 영업지원.jpg",
  },
  {
    id: "66f0d50854528140cb4d73bc",
    img: "/assets/imgs/job-announce/경영지원팀_회계세무.jpg",
  },
  {
    id: "667a0f64581100c92983c9a7",
    img: "/assets/imgs/job-announce/기술2부_SOS 개발.jpg",
  },
  {
    id: "66f0d51e54528140cb4d73e7",
    img: "/assets/imgs/job-announce/경영지원팀_IR.jpg",
  },
  {
    id: "66f0e30654528140cb4d7606",
    img: "/assets/imgs/job-announce/기술3부_RF설계 개발.jpg",
  },
  {
    id: "66f0e2f254528140cb4d75fe",
    img: "/assets/imgs/job-announce/기술3부_Antenna설계 개발.jpg",
  },
  {
    id: "662890a351ceb11f8fab0e6b",
    img: "/assets/imgs/job-announce/PA팀_PA.jpg",
  },
  {
    id: "6628909451ceb11f8fab0e67",
    img: "/assets/imgs/job-announce/기술7부_애플리케이션SW개발.jpg",
  },
  {
    id: "66f0d5db54528140cb4d740d",
    img: "/assets/imgs/job-announce/기술7부_AI엔지니어.jpg",
  },
  {
    id: "66f0d5f954528140cb4d7415",
    img: "/assets/imgs/job-announce/기술7부_레이다신호처리알고리즘개발.jpg",
  },
  {
    id: "66f0d64a54528140cb4d741d",
    img: "/assets/imgs/job-announce/기술7부_시스템엔지니어.jpg",
  },
  {
    id: "66f0e31e54528140cb4d760f",
    img: "/assets/imgs/job-announce/기술3부_기구설계개발.jpg",
  },
  {
    id: "6628901051ceb11f8fab0e45",
    img: "/assets/imgs/job-announce/기술1부_전력전자 하드웨어 개발.jpg",
  },
  {
    id: "66f0e33c54528140cb4d7619",
    img: "/assets/imgs/job-announce/기술1부_FPGA 로직 개발.jpg",
  },
  {
    id: "66f0e4a554528140cb4d7655",
    img: "/assets/imgs/job-announce/기술1부_디지털 하드웨어 개발.jpg",
  },
];
