import { route } from "../router/route";

export const menus = [
  // 사업소개
  {
    title: "MENU_2",
    originLink: route.business,
    link: route.business,
    exposure: true,
    subMenus2: [
      {
        title: "MENU_2_6",
        link: `${route.business}#${route.satellite_system}`,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_2_7",
        link: `${route.business}#${route.satellite_mounted_device}`,
        subMenus3: [],
        exposure: true,
      },
      // {
      //   title: "MENU_2_8",
      //   link: `${route.business}#${route.electric_ground}`,
      //   subMenus3: [],
      //   exposure: true,
      // },
      {
        title: "MENU_2_9",
        link: `${route.business}#${route.civilian_device}`,
        subMenus3: [],
        exposure: true,
      },
    ],
    //   {
    //     title: "MENU_2_0",
    //     link: route.business,
    //     subMenus3: [],
    //     exposure: true,
    //   },
    //   {
    //     title: "MENU_2_1",
    //     link: route.constellation,
    //     subMenus3: [],
    //     exposure: true,
    //   },
    //   {
    //     title: "MENU_2_2",
    //     originLink: route.old_space,
    //     link: route.nextGenerationHeavySatellite,
    //     exposure: true,
    //     subMenus3: [
    //       {
    //         title: "MENU_2_2_1",
    //         originLink: route.satellite_mounted,
    //         link: route.nextGenerationHeavySatellite,
    //         exposure: true,
    //         subMenus4: [
    //           {
    //             id: [1, 8],
    //             title: "MENU_2_2_1_1",
    //             link: route.nextGenerationHeavySatellite,
    //             exposure: true,
    //           },
    //           {
    //             id: [2, 9],
    //             title: "MENU_2_2_1_2",
    //             link: route.lunarExploration,
    //             exposure: true,
    //           },
    //           {
    //             id: [5, 10],
    //             title: "MENU_2_2_1_3",
    //             link: route.imageDataProcessing,
    //             exposure: true,
    //           },
    //           {
    //             id: [4, 11],
    //             title: "MENU_2_2_1_4",
    //             link: route.explorationComputer,
    //             exposure: true,
    //           },
    //           {
    //             id: [6, 12],
    //             title: "MENU_2_2_1_5",
    //             link: route.controlDevice,
    //             exposure: true,
    //           },
    //         ],
    //       },
    //       {
    //         title: "MENU_2_2_2",
    //         originLink: route.electric_ground,
    //         link: route.xBand,
    //         exposure: true,
    //         subMenus4: [
    //           {
    //             id: [7, 13],
    //             title: "MENU_2_2_2_1",
    //             link: route.xBand,
    //             exposure: true,
    //           },
    //           {
    //             id: [19, 21],
    //             title: "MENU_2_2_2_2",
    //             link: route.bodyPowerMeter,
    //             exposure: true,
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     title: "MENU_2_3",
    //     originLink: route.new_space,
    //     link: route.catis,
    //     exposure: true,
    //     subMenus3: [
    //       {
    //         title: "MENU_2_3_1",
    //         originLink: route.lumirX,
    //         link: route.catis,
    //         exposure: true,
    //         subMenus4: [
    //           {
    //             id: [22, 15],
    //             title: "MENU_2_3_1_1",
    //             link: route.catis,
    //             exposure: true,
    //           },
    //           {
    //             id: [23, 16],
    //             title: "MENU_2_3_1_2",
    //             link: route.sardip,
    //             exposure: true,
    //           },
    //         ],
    //       },
    //       {
    //         id: [24, 17],
    //         title: "MENU_2_3_2",
    //         link: route.lumirV,
    //         subMenus4: [],
    //         exposure: true,
    //       },
    //       {
    //         title: "MENU_2_3_3",
    //         originLink: route.new_business,
    //         link: route.dataFusion,
    //         exposure: true,
    //         subMenus4: [
    //           {
    //             title: "MENU_2_3_3_1",
    //             link: route.dataFusion,
    //             exposure: true,
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     title: "MENU_2_4",
    //     link: route.serviceScope,
    //     subMenus3: [],
    //     exposure: true,
    //   },
    //   {
    //     title: "MENU_2_5",
    //     link: route.gavs,
    //     subMenus3: [],
    //     id: [25, 18],
    //     exposure: true,
    //   },
    // ],
  },

  // 서비스
  {
    title: "MENU_6",
    originLink: route.service,
    link: route.service,
    exposure: true,
    subMenus2: [
      {
        title: "MENU_6_1",
        link: `${route.service}#${route.sarImage}`,
        exposure: true,
        subMenus3: [],
      },
      {
        title: "MENU_6_2",
        link: `${route.service}#${route.ai}`,
        exposure: true,
        subMenus3: [],
      },
      {
        title: "MENU_6_3",
        link: `${route.service}#${route.disaster}`,
        exposure: true,
        subMenus3: [],
      },
      {
        title: "MENU_6_4",
        link: `${route.service}#${route.monitoring}`,
        exposure: true,
        subMenus3: [],
      },
      {
        title: "MENU_6_5",
        link: `${route.service}#${route.water_facility}`,
        exposure: true,
        subMenus3: [],
      },
    ],
  },

  // 회사 소개
  {
    title: "MENU_1",
    originLink: route.introduce,
    link: route.overview,
    exposure: true,
    subMenus2: [
      {
        title: "MENU_1_1",
        link: route.overview,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_1_2",
        link: route.history,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_1_3",
        // originLink: route.major_performance,
        link: route.heritage,
        exposure: true,
        subMenus3: [
          // {
          //   title: "MENU_1_3_1",
          //   link: route.heritage,
          //   subMenus4: [],
          //   exposure: true,
          // },
          // {
          //   title: "MENU_1_3_2",
          //   link: route.nationalRnD,
          //   subMenus4: [],
          //   exposure: true,
          // },
          // {
          //   title: "MENU_1_3_3",
          //   link: route.certification,
          //   subMenus4: [],
          //   exposure: true,
          // },
        ],
      },
      {
        title: "MENU_1_4",
        link: route.workspace,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_1_5",
        link: route.direction,
        subMenus3: [],
        exposure: true,
      },
    ],
  },

  // 투자정보 - QA 변경 전 메뉴
  // {
  //   title: "MENU_3",
  //   originLink: route.investment,
  //   link: `${route.managementInfo}/1`,
  //   subMenus2: [
  //     {
  //       title: "MENU_3_1",
  //       originLink: route.financial_info,
  //       link: `${route.managementInfo}/1`,
  //       subMenus3: [
  //         {
  //           title: "MENU_3_1_1",
  //           link: `${route.managementInfo}/1`,
  //           subMenus4: [],
  //         },
  //         {
  //           title: "MENU_3_1_2",
  //           link: route.businessReport,
  //           subMenus4: [],
  //         },
  //         {
  //           title: "MENU_3_1_3",
  //           link: route.reviewReport,
  //           subMenus4: [],
  //         },
  //         {
  //           title: "MENU_3_1_4",
  //           link: route.governanceReport,
  //           subMenus4: [],
  //         },
  //         {
  //           title: "MENU_3_1_5",
  //           link: route.electronicAnnounce,
  //           subMenus4: [],
  //         },
  //       ],
  //     },
  //     {
  //       title: "MENU_3_2",
  //       originLink: route.performance,
  //       link: route.performanceAnnounce,
  //       subMenus3: [
  //         {
  //           title: "MENU_3_2_1",
  //           link: route.performanceAnnounce,
  //           subMenus4: [],
  //         },
  //         {
  //           title: "MENU_3_2_2",
  //           link: route.IREvent,
  //           subMenus4: [],
  //         },
  //         {
  //           title: "MENU_3_2_3",
  //           link: route.eventMaterials,
  //           subMenus4: [],
  //         },
  //       ],
  //     },
  //     {
  //       title: "MENU_3_3",
  //       originLink: route.shareholder,
  //       link: route.communication,
  //       subMenus3: [
  //         {
  //           title: "MENU_3_3_1",
  //           link: route.communication,
  //           subMenus4: [],
  //         },
  //         {
  //           title: "MENU_3_3_2",
  //           link: `${route.meeting}/1`,
  //           subMenus4: [],
  //         },
  //       ],
  //     },
  //     {
  //       title: "MENU_3_4",
  //       link: route.stockInfo,
  //       subMenus3: [],
  //     },
  //     {
  //       title: "MENU_3_5",
  //       link: `${route.notice}/1`,
  //       subMenus3: [],
  //     },
  //   ],
  // },

  // 투자정보 - QA 요청 들어온 메뉴
  {
    title: "MENU_3",
    originLink: route.investment,
    link: `${route.electronicAnnounce}`,
    exposure: true,
    subMenus2: [
      {
        title: "MENU_3_1_5",
        link: route.electronicAnnounce,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_3_1_6",
        link: `${route.ir_info}/1`,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_3_1",
        // originLink: route.financial_info,
        link: `${route.managementInfo}/1`,
        subMenus3: [],
        exposure: true,
      },
      // {
      //   title: "MENU_3_3_2",
      //   link: `${route.meeting}/1`,
      //   subMenus3: [],
      //   exposure: true,
      // },
    ],
  },

  // 사이버 홍보
  {
    title: "MENU_4",
    originLink: route.cyber_relation,
    link: `${route.newArticle}/1`,
    exposure: true,
    subMenus2: [
      {
        title: "MENU_4_1",
        link: `${route.newArticle}/1`,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_4_2",
        link: `${route.lumirNews}/1`,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_4_3",
        link: `${route.videoGallery}`,
        originLink: route.public_relations_office,
        exposure: true,
        subMenus3: [
          {
            title: "MENU_4_3_1",
            link: route.videoGallery,
            subMenus4: [],
            exposure: true,
          },
          {
            title: "MENU_4_3_2",
            link: `${route.brochure}/1`,
            subMenus4: [],
            exposure: true,
          },
        ],
      },
    ],
  },

  //채용 홍보
  {
    title: "MENU_5",
    originLink: route.recruit,
    link: route.talentType,
    exposure: true,
    subMenus2: [
      {
        title: "MENU_5_1",
        link: route.talentType,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_5_2",
        link: `${route.jobAnnounce}/1`,
        subMenus3: [],
        exposure: true,
      },
      {
        title: "MENU_5_3",
        link: route.faq,
        subMenus3: [],
        exposure: true,
      },
    ],
  },
];
