import { Navigate, Route, Routes } from "react-router-dom";
import Constellation from "../pages/business/constellation";
import Gavs from "../pages/business/gavs/gavs";
import LumirV from "../pages/business/newSpace/lumirV/lumirV";
import Catis from "../pages/business/newSpace/lumirX/catis/catis";
import DataFusion from "../pages/business/newSpace/newBusiness/dataFusion";
import BodyPowerMeter from "../pages/business/oldSpace/Electric/bodyPowerMeter/bodyPowerMeter";
import XBand from "../pages/business/oldSpace/Electric/xBand/xBand";
import ControlDevice from "../pages/business/oldSpace/SatelliteMounted/controlDevice/controlDevice";
import ExplorationComputer from "../pages/business/oldSpace/SatelliteMounted/explorationComputer/explorationComputer";
import ImageDataProcessing from "../pages/business/oldSpace/SatelliteMounted/imageDataProcessing/imageDataProcessing";
import LunarExploration from "../pages/business/oldSpace/SatelliteMounted/lunarExploration/lunarExploration";
import NextGenerationHeavySatellite from "../pages/business/oldSpace/SatelliteMounted/nextGenerationHeavySatellite/nextGenerationHeavySatellite";
import ServiceScope from "../pages/business/serviceScope";
import LumirNews from "../pages/cyberRelation/lumirNews";
import LumirNewsDetail from "../pages/cyberRelation/lumirNewsDetail";
import NewArticle from "../pages/cyberRelation/newArticle";
import Brochure from "../pages/cyberRelation/relationOffice/brochure";
import VideoGallery from "../pages/cyberRelation/relationOffice/videoGallery";
import Direction from "../pages/introduce/direction";
import History from "../pages/introduce/history";
import Certification from "../pages/introduce/majorPerformance/certification";
import NationalRnD from "../pages/introduce/majorPerformance/nationalRnD";
import BusinessReport from "../pages/investment/financialInfo/businessReport";
import IrInfoPage from "../pages/investment/financialInfo/irInfo/page";
import IrInfoDetailPage from "../pages/investment/financialInfo/irInfoDetail/page";

import ElectronicAnnounce from "../pages/investment/financialInfo/electronicAnnounce";
import GovernanceReport from "../pages/investment/financialInfo/governanceReport";
import ManagementInfo from "../pages/investment/financialInfo/managementInfo";
import ManagementInfoDetail from "../pages/investment/financialInfo/managementInfoDetail";
import ReviewReport from "../pages/investment/financialInfo/reviewReport";
import Communication from "../pages/investment/generalShareholder.jsx/communication";
import Meeting from "../pages/investment/generalShareholder.jsx/meeting";
import StockInfo from "../pages/investment/generalShareholder.jsx/stockInfo";
import Notice from "../pages/investment/notice/notice";
import NoticeDetail from "../pages/investment/notice/noticeDetail";
import EventMaterials from "../pages/investment/performanceTable/eventMaterials";
import IREvent from "../pages/investment/performanceTable/irEvent";
import PerformanceAnnounce from "../pages/investment/performanceTable/performanceAnnounce";
import { route } from "./route";

import ProductsRenewal from "../pages/business/ProductsRenewal";
import LumirX from "../pages/business/renewalDetail/satelliteSystem/LumirX";
import ServicePage from "../pages/data-sardip/DataPage";
import HomeRenewal from "../pages/home/HomeRenewal";
import IntroduceRenewal from "../pages/introduce/renewal/IntroduceRenewal";
import MajorPerformanceRenewal from "../pages/introduce/renewal/MajorPerformanceRenewal";
import Workspace from "../pages/introduce/renewal/Workspace";
import { default as NotFoundPage } from "../pages/NotFoundPage";
import JobAnnounce from "../pages/recruit/jobAnnounce";
import JobAnnounceDetail from "../pages/recruit/jobAnnounceDetail";
import Talent from "../pages/recruit/renewal/Talent";
import PageTitleUpdater from "./page-router-updater";

// import WorkSpace from "../pages/introduce/workPlace";

const Router = () => {
  return (
    <>
      <PageTitleUpdater />
      <Routes>
        {/* default */}
        {/* <Route path={route.default} element={<Home />} /> */}
        <Route path={route.default} element={<HomeRenewal />} />
        {/* 홈 */}
        {/* <Route path={route.home} element={<Home />} /> */}
        <Route path={route.home} element={<HomeRenewal />} />
        {/* 회사소개 */}
        <Route path={route.overview} element={<IntroduceRenewal />} />
        <Route path={route.history} element={<History />} />
        <Route path={route.heritage} element={<MajorPerformanceRenewal />} />
        <Route path={route.nationalRnD} element={<NationalRnD />} />
        <Route path={route.certification} element={<Certification />} />
        <Route path={`${route.workspace}`} element={<Workspace />} />
        {/* <Route path={route.workspace} element={<WorkSpace />} /> */}
        <Route path={route.direction} element={<Direction />} />
        <Route path={route.constellation} element={<Constellation />} />
        <Route
          path={route.nextGenerationHeavySatellite}
          element={<NextGenerationHeavySatellite />}
        />
        <Route path={route.lunarExploration} element={<LunarExploration />} />
        <Route
          path={route.imageDataProcessing}
          element={<ImageDataProcessing />}
        />
        <Route
          path={route.explorationComputer}
          element={<ExplorationComputer />}
        />
        <Route path={route.controlDevice} element={<ControlDevice />} />
        <Route path={route.xBand} element={<XBand />} />
        <Route path={route.bodyPowerMeter} element={<BodyPowerMeter />} />
        <Route path={route.catis} element={<Catis />} />
        {/* <Route path={route.sardip} element={<Sardip />} /> */}
        {/* <Route path={route.data} element={<Sardip />} /> */}
        <Route path={route.lumirV} element={<LumirV />} />
        <Route path={route.dataFusion} element={<DataFusion />} />
        <Route path={route.serviceScope} element={<ServiceScope />} />
        <Route path={route.gavs} element={<Gavs />} />
        <Route
          path={route.managementInfo + "/:page"}
          element={<ManagementInfo />}
        />
        <Route
          path={route.managementInfoDetail + "/:id"}
          element={<ManagementInfoDetail />}
        />
        <Route path={route.businessReport} element={<BusinessReport />} />
        <Route path={route.reviewReport} element={<ReviewReport />} />
        <Route path={route.governanceReport} element={<GovernanceReport />} />
        <Route path={route.ir_info + "/:page"} element={<IrInfoPage />} />
        <Route
          path={route.ir_info_detail + "/:id"}
          element={<IrInfoDetailPage />}
        />
        <Route
          path={route.electronicAnnounce}
          element={<ElectronicAnnounce />}
        />
        <Route
          path={route.performanceAnnounce}
          element={<PerformanceAnnounce />}
        />
        <Route path={route.IREvent} element={<IREvent />} />
        <Route path={route.eventMaterials} element={<EventMaterials />} />
        <Route path={route.communication} element={<Communication />} />
        <Route path={route.meeting + "/:page"} element={<Meeting />} />
        <Route path={route.stockInfo} element={<StockInfo />} />
        <Route path={route.notice + "/:page"} element={<Notice />} />
        <Route path={route.noticeDetail + "/:id"} element={<NoticeDetail />} />
        <Route path={route.newArticle + "/:page"} element={<NewArticle />} />
        <Route path={route.lumirNews + "/:page"} element={<LumirNews />} />
        <Route
          path={route.lumirNewsDetail + "/:id"}
          element={<LumirNewsDetail />}
        />
        <Route path={route.brochure + "/:page"} element={<Brochure />} />
        <Route path={route.videoGallery} element={<VideoGallery />} />
        <Route path={route.talentType} element={<Talent />} />
        <Route path={route.jobAnnounce + "/:page"} element={<JobAnnounce />} />
        <Route
          path={route.jobAnnounceDetail + "/:id"}
          element={<JobAnnounceDetail />}
        />
        {/* Renewal */}
        {/* <Route path="/main" element={<HomeRenewal />} /> */}
        <Route path="/products" element={<ProductsRenewal />} />
        <Route path={route.lumirX_renewal} element={<LumirX />} />
        {/* Data */}
        <Route path={route.service} element={<ServicePage />} />
        {/* error */}
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </>
  );
};

export default Router;
